import axios from 'axios'

class ApiClient {

    constructor (baseURL) {
        this.baseURL = baseURL
    }

    get (url, params) {
        return this._makeRequest('GET', url, params)
    }

    post (url, body, params) {
        return this._makeRequest('POST', url, params, body)
    }

    _makeRequest (method, url, params = null, data = null) {
        return axios({ method, baseURL: this.baseURL, url, params, data }).then(res => res.data)
    }
}

const baseApiURL = {
    development: 'https://us-central1-documented-ny-wages.cloudfunctions.net',
    production: 'https://us-central1-documented-ny-wages.cloudfunctions.net',
}[process.env.NODE_ENV]

export const api = new ApiClient(baseApiURL)
