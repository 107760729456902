import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from '@/shared/styles/colors.scss'

Vue.use(Vuetify)

const options = {
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                ...colors,
            },
        },
    },
}

export default new Vuetify(options)
