import Vue from 'vue'
import VueRouter from 'vue-router'

const Auth = () => import(/* webpackChunkName: "auth" */ '@/views/Auth.vue')
const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
const WagesSearch = () => import(/* webpackChunkName: "search" */ '@/views/WagesSearch.vue')
const WagesDetail = () => import(/* webpackChunkName: "detail" */ '@/views/WagesDetail.vue')
const PermitsSearch = () => import(/* webpackChunkName: "search" */ '@/views/PermitsSearch.vue')
const PermitsDetail = () => import(/* webpackChunkName: "detail" */ '@/views/PermitsDetail.vue')

Vue.use(VueRouter)

const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: Auth,
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/wages/search',
        name: 'wages-search',
        component: WagesSearch,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/wages/detail/:slug',
        name: 'wages-detail',
        component: WagesDetail,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/permits/search',
        name: 'permits-search',
        component: PermitsSearch,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/permits/detail/:slug',
        name: 'permits-detail',
        component: PermitsDetail,
        meta: {
            requiresAuth: false,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})


router.beforeEach((to, _, next) => {
    if (!to.meta.requiresAuth || localStorage.getItem('user-password') === atob('ZG9jdW1lbnRlZG55d2FnZXM')) {
        next()
    } else {
        next('/auth')
    }
})

export default router
