import Vue from 'vue'
import Vuex from 'vuex'
import { api } from '../util/api'

Vue.use(Vuex)

const SET_STATE_PROPERTIES = 'SET_STATE_PROPERTIES'

export default new Vuex.Store({
    state: {
        cases: [],
        page: null,
        perPage: null,
        totalCases: null,
        totalPages: null,
        pins: [],
    },
    getters: {
        cases: state => state.cases,
        page: state => state.page,
        perPage: state => state.perPage,
        totalCases: state => state.totalCases,
        totalPages: state => state.totalPages,
        pins: state => state.pins,
    },
    mutations: {
        SET_STATE_PROPERTIES (state, properties) {
            Object.assign(state, properties)
        },
        RESET_STATE (state) {
            Object.assign(state, {
                cases: [],
                page: null,
                perPage: null,
                totalCases: null,
                totalPages: null,
            })
        },
    },
    actions: {
        search ({ commit }, query) {
            commit('RESET_STATE')
            return api.get('search', query)
                .then(res => commit(SET_STATE_PROPERTIES, res))
        },
        searchPermits ({ commit }, query) {
            commit('RESET_STATE')
            return api.get('searchJobs', query)
                .then(res => commit(SET_STATE_PROPERTIES, res))
        },
        fetchPins ({ commit }) {
            commit(SET_STATE_PROPERTIES, { pins: [] })
            return api.get('pins')
                .then(pins => commit(SET_STATE_PROPERTIES, { pins }))
        },
    },
})
