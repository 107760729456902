<template>
    <v-app>
        <v-app-bar app absolute="true" flat class="px-0 header-main-bar" color="white" height="90">
            <v-card class="d-flex align-content-center justify-space-start align-items-center flex-grow-1 flex-shrink-0 px-0" flat tile>
                <div class="div-logo-contain">
                    <img class="header__logo" src="./assets/documentedny_logo.png" alt="Documented NY logo" @click="onLogoClick">
                </div>
                <div class="header-vertical-line">&nbsp;</div>
                <div class="header-toolbar pr-4 flex-fill">NEW YORK WAGE DISPUTE DATA</div>
            </v-card>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    methods: {
        onLogoClick () {
            this.$router.push('/')
        },
    },
}
</script>


<style lang="scss">
@import '@/shared/styles/typography';

#app {
    @include regular-font;
}

.v-toolbar.v-toolbar--absolute {
  z-index: 3 !important;
}
.div-logo-contain{
  padding-right: 16px;
}
.header-vertical-line{
  height: 90px;
  border-left: 1px solid #000;
}
.header-toolbar{
  font-family: 'Poppins', sans-serif;
  font-weight: 650;
  font-size: 1.25rem;
  text-align: right;
}

@media (max-width: 576px) {
  .header-toolbar{
    font-family: 'Poppins', sans-serif;
    font-weight: 650;
    font-size: 1rem;
    padding-left: 16px;
    line-height: 1rem;
  }
}
</style>

<style lang="scss" scoped>
.header__logo {
    cursor: pointer;
    width:100%;
    max-width:230px;
    height: auto;
}
.footer {
    z-index: 10;

    &__text {
        color: white;
        margin: 24px auto;
    }
}

</style>
